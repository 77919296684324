a {
  text-decoration: none;
}
.swal-button {
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #0000ff;
  font-size: 12px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
.swal-text {
  background-color: #f8f9fa;
  padding: 20px;
  display: block;
  margin: 22px;
  text-align: center;
  color: #000;
}
.swal-overlay {
  background-color: #0000ff3d;
}
.walton-card {
  width: 100%;
  min-height: 230px;
  background-image: url(./Images/digital-campaign.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media screen and (min-width: 600px) {
  .walton-card {
    min-height: 500px;
  }
}
